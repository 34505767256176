import React, { useEffect, useState } from "react";
import Footer from "../Components/Footer";

const Blog = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {loading ? (
        <div className="w-full h-screen flex justify-center items-center">
          <div
            className="rounded-md h-16 w-16 border-4 border-t-4 border-blue-500 animate-spin"
            role="status"
            aria-label="Loading"
          ></div>
        </div>
      ) : (
        <div>
          <h1
            className="text-center mt-12 mb-12 text-xl font-bold text-blue-500 md:text-3xl"
            data-aos="fade-in"
          >
            BLOG
          </h1>
          <div className="mx-auto space-y-10 overflow-hidden">
            <div className="w-fit mx-auto">
              <iframe
                src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2F100064155602022%2Fvideos%2F8947874165330248%2F&show_text=true&width=560&t=0"
                height="429"
                className="mx-auto w-80 sm:w-[80vh] lg:w-[100vh] rounded-md"
                scrolling="no"
                loading="lazy"
                frameBorder="0"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
            <iframe
              src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3D552144004355838%26id%3D100064155602022%26substory_index%3D552144004355838&show_text=true&width=500"
              width="500"
              height="478"
              className="mx-auto w-80 sm:w-[80vh] lg:w-[100vh] rounded-md"
              scrolling="no"
              loading="lazy"
              frameBorder="0"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
            <iframe
              src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2F100064155602022%2Fvideos%2F953440956831975%2F&show_text=true&width=560&t=0"
              height="429"
              className="mx-auto w-80 sm:w-[80vh] lg:w-[100vh] rounded-md"
              scrolling="no"
              loading="lazy"
              frameBorder="0"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
            <iframe
              src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid025dARQk6ioSjshaGtheJpbyjsH9Wp5PSwTJpKtbjbfd8WuexhxG4dBwzxmPsoiqubl%26id%3D100064155602022&show_text=true&width=500"
              width="500"
              height="551"
              className="mx-auto w-80 sm:w-[80vh] lg:w-[100vh] rounded-md"
              scrolling="no"
              loading="lazy"
              frameBorder="0"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
            <iframe
              src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid0Zcnq32eGJRzDpJJH8eETkdsMHrUPCgkNL3CxKjAdByWPRkFWgub8Nz49HzsMZWnXl%26id%3D100064155602022&show_text=true&width=500"
              width="500"
              height="660"
              className="mx-auto w-80 sm:w-[80vh] lg:w-[100vh] rounded-md"
              scrolling="no"
              loading="lazy"
              frameBorder="0"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
          <div className="mt-28">
            <Footer />
          </div>
        </div>
      )}
    </>
  );
};

export default Blog;
