import React, { useEffect, Suspense } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import { useLanguage } from '../Components/Languagecontext';

// Optimized Images
import image1 from "../Assets/Icons/adventure-icon-1-70x70.webp";
import image2 from "../Assets/Icons/Your-Safety-First-70x70.webp";
import image3 from "../Assets/Icons/True-Off-Road-Endurance-Rides-70x70-5.webp";
import image4 from "../Assets/Icons/High-end-lodging-70x70.webp";
import image5 from "../Assets/Icons/Genuine-OFF-ROADS-bikes-70x70.webp";
import image6 from "../Assets/Icons/Agile-and-Always-there-Team-70x70.webp";

// Lazy-loaded components
const Videocard = React.lazy(() => import('../Components/Videocard'));
const Descriptioncard = React.lazy(() => import('../Components/Descriptioncard'));
const PortraitCarousel = React.lazy(() => import('../Components/Carousel'));
const Lg_card = React.lazy(() => import('../Components/Lg_card'));
const Testimonials = React.lazy(() => import('../Components/Testimonials'));
const MagazineCarousel = React.lazy(() => import('../Components/MagazineCarousel'));
const Footer = React.lazy(() => import('../Components/Footer'));

const Home = () => {
  const { translang } = useLanguage();

  useEffect(() => {
    AOS.init({
      duration: 800, // Reduced animation duration for better performance
      offset: 200, // Adjusted offset for smoother animations
      easing: 'ease-in-out',
      once: true,
    });
  }, []);

  return (
    <>
      <div className="w-full overflow-hidden">
        <Suspense fallback={   <div className="w-full h-screen flex justify-center items-center">
          <div className="rounded-md h-16 w-16 border-4 border-t-4 border-blue-500 animate-spin"></div>
        </div>}>
          <div>
            <div className="relative h-auto overflow-hidden">
              <Videocard />
            </div>

            <div className="w-full overflow-hidden">
              <Descriptioncard />
            </div>

            <div className="w-full mt-16 md:mt-3 mb-20 lg:mb-4">
              <PortraitCarousel />
            </div>

            <div className="lg:w-full mx-auto md:mt-20" data-aos="fade-down">
              <Link to="/whytotravel">
                <div className="lg:max-w-7xl mx-auto">
                  <h1 className="mx-auto w-full text-center uppercase text-2xl 2xl:text-7xl md:text-3xl font-bold text-blue-500">
                    {translang("why1")}
                  </h1>

                  <div className="mt-8 md:grid md:grid-cols-2 lg:grid lg:grid-cols-3 overflow-hidden">
                    <Lg_card img={image2} title={translang("whytitle1")} des={translang("whydes1")} />
                    <Lg_card img={image1} title={translang("whytitle2")} des={translang("whydes2")} />
                    <Lg_card img={image5} title={translang("whytitle3")} des={translang("whydes3")} />
                    <Lg_card img={image3} title={translang("whytitle4")} des={translang("whydes4")} />
                    <Lg_card img={image6} title={translang("whytitle5")} des={translang("whydes5")} />
                    <Lg_card img={image4} title={translang("whytitle6")} des={translang("whydes6")} />
                  </div>
                </div>
              </Link>
            </div>

            <div>
              <Testimonials />
            </div>

            <div className="lg:mt-10 md:mb-5" data-aos="fade-up">
              <MagazineCarousel />
            </div>
          </div>
        </Suspense>
      </div>

      <Suspense fallback={<div>Loading Footer...</div>}>
        <Footer />
      </Suspense>
    </>
  );
};

export default Home;
