import React, { useEffect, Suspense } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useLanguage } from '../Components/Languagecontext';

// Optimized Images
import ktm from '../Assets/Home/WhatsApp Image 2024-12-19 at 1.47.42 PM.webp';
import img from '../Assets/Home/about.webp';
// Lazy-loaded components
const Footer = React.lazy(() => import('../Components/Footer'));



const About = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      offset: 200,    // Offset (in px) from the original trigger point
      easing: 'ease-in-out', // Easing function for animations
      once: true,     // Trigger animation only once
    });
  }, []);

  const { translang } = useLanguage();

  return (
    <>
      <div className="w-full overflow-hidden">
        <p className="text-center mt-12 text-xl font-bold text-blue-500 md:text-3xl uppercase mb-12">
          {translang('aboutus')}
        </p>

        <div className="w-80 md:w-[35%] lg:w-[40%] mx-auto">
          <img src={ktm} alt="Adventure Bike" className="mx-auto rounded-2xl" data-aos="fade-right" />
        </div>

        <div className="mx-auto text-justify w-72 mt-8 lg:w-[150vh]">
          {['about12', 'about13', 'about3', 'about4'].map((key, index) => (
            <p className="pb-8" data-aos="fade-right" key={index}>
              {translang(key)}
            </p>
          ))}
        </div>

        <div className="text-justify mx-auto w-72 lg:w-[150vh]">
          <h1 className="font-thin text-3xl mb-8 text-gray-500" data-aos="fade-in">
            {translang('abouthead')}
          </h1>
          {['about5', 'about6', 'about7', 'about8'].map((key, index) => (
            <p className="pb-8" data-aos="fade-right" key={index}>
              {translang(key)}
            </p>
          ))}
        </div>
      </div>

      <div className="w-full">
        <img src={img} alt="About Us" className="mx-auto" />
      </div>

      <div className="mt-28">
        <Suspense fallback={<div>Loading Footer...</div>}>
          <Footer />
        </Suspense>
      </div>
    </>
  );
};

export default About;
