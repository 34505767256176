import React, { useEffect } from 'react';
import Footer from '../Components/Footer';
import { useLanguage } from '../Components/Languagecontext';

const Gallery = () => {
  const { translang } = useLanguage();

  useEffect(() => {
    // Load Instagram embed script
    const script = document.createElement('script');
    script.src = 'https://www.instagram.com/embed.js';
    script.async = true;

    // Attach a listener to ensure the script is fully loaded
    script.onload = () => {
      if (window.instgrm && window.instgrm.Embeds) {
        window.instgrm.Embeds.process(); // Initialize Instagram embeds
      }
    };

    // Append the script to the document
    document.body.appendChild(script);

    // Cleanup script on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <div className="p-5 max-w-screen-lg mx-auto">
        <p className="text-center mt-7 text-xl font-bold text-blue-500 md:text-3xl mb-12">
          {translang('gallery')}
        </p>
      </div>

      <div className="w-full">
        {/* Instagram Embed Blocks */}
        {[
          'https://www.instagram.com/reel/DCdeDhVpzU3/?utm_source=ig_embed&amp;utm_campaign=loading',
          'https://www.instagram.com/reel/DCcFthhSNyB/?utm_source=ig_embed&utm_campaign=loading',
          'https://www.instagram.com/reel/DBX-hrKSDmk/?utm_source=ig_embed&amp;utm_campaign=loading',
          'https://www.instagram.com/p/DBOPzMsymoV/?utm_source=ig_embed&amp;utm_campaign=loading',
          'https://www.instagram.com/reel/C-rz_opyav0/?utm_source=ig_embed&amp;utm_campaign=loading',
        ].map((url, index) => (
          <div
            key={index}
            className="instagram-embed md:w-[40%] w-[98%] mx-auto"
            style={{
              padding: '16px',
              background: '#FFF',
              border: '1px solid #e1e1e1',
              borderRadius: '8px',
            }}
          >
            <blockquote
              className="instagram-media"
              data-instgrm-captioned
              data-instgrm-permalink={url}
              data-instgrm-version="14"
              style={{
                background: '#FFF',
                border: '0',
                borderRadius: '3px',
                boxShadow: '0 0 1px 0 rgba(0, 0, 0, 0.5), 0 1px 10px 0 rgba(0, 0, 0, 0.15)',
                margin: '1px',
                maxWidth: '540px',
                minWidth: '326px',
                padding: '0',
                width: '100%',
              }}
            />
          </div>
        ))}
   <br />
   <br />
        <Footer />
      </div>
    </>
  );
};

export default Gallery;
